import React, { useEffect, useState } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { supabase } from "../config/supabaseClient";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {format} from "date-fns";
import {useSelector} from "react-redux";

const DeployedApps = ({ selected }) => {
    const [apps, setApps] = useState([]);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const history = useNavigate();
    const user = useSelector((state) => state.auth.user);


    useEffect(() => {
        const getApps = async () => {
            if (selected === 'apps') {
                const deployedApps = await fetchDeployedApps();
                setApps(deployedApps);
            }
        };
        getApps().then(r => {});
    }, [selected]);

    const fetchDeployedApps = async () => {
        const { data, error } = await supabase.from('deployed_apps').select('*').eq('user_id', user.user_id);
        if (error) {
            console.error('Error fetching deployed apps:', error);
            return [];
        }
        return data;
    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), "MMMM d, yyyy, h:mm a");
    };

    const handleAppClick = (id) => {
        history(`/deploy/apps/${id}`);
    }

    return (
        <Box sx={{ marginBottom: '40px', justifyContent: isMobile ? 'center' : 'flex-start' }}>
            <Typography variant="h4">Apps</Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2 , background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(100, 100, 100, 0.7))'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Deployed Date</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apps.length > 0 ? (
                            apps.map(app => (
                                <TableRow
                                    key={app.deployed_app_id}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleAppClick(app.deployed_app_id)}
                                >
                                    <TableCell>{app.deployed_app_name}</TableCell>
                                    <TableCell>{formatDate(app.deployed_at)}</TableCell>
                                    <TableCell>{app.status}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={2}>No apps are deployed yet.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default DeployedApps;
