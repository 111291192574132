import React, { useMemo, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import './css/ChipOfferList.css';
import Modal from "./Modal";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Login from "./Login";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";
import AppModal from "./AppModal";
import {signOut} from "firebase/auth";

const ChipOfferList = ({ chipOffers, flagEnabled }) => {
    const [showModal, setShowModal] = useState(false);
    const applications = useSelector((state) => state.apps.applications);
    const [currentUser] = useAuthState(auth);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [selectedOffer,setSelectedOffer] = useState(null);

    const history = useNavigate();

    const isMobile = useMediaQuery({ maxWidth: 500 });
    const groupedOffers = useMemo(() => {
        return chipOffers.reduce((acc, offer) => {
            (acc[offer.type] = acc[offer.type] || []).push(offer);
            return acc;
        }, {});
    }, [chipOffers]);

    const cheapestCategory = useMemo(() => {
        let cheapestPrice = Infinity;
        let maximumVram = -1 * Infinity;
        let cheapestCat = '';

        Object.entries(groupedOffers).forEach(([category, offers]) => {
            const categoryMinPrice = Math.min(...offers.map(o => o.pricing));
            const categoryMaxVram = Math.max(...offers.map(o => o.vram));
            if (categoryMinPrice < cheapestPrice) {
                cheapestPrice = categoryMinPrice;
                cheapestCat = category;
            } else if (categoryMinPrice === cheapestPrice && categoryMaxVram > maximumVram) {
                maximumVram = categoryMaxVram;
                cheapestCat = category;
            }
        });
        return cheapestCat;
    }, [groupedOffers]);

    const [expandedCategories, setExpandedCategories] = useState(Object.keys(groupedOffers).reduce((acc, category) => {
        acc[category] = true; // Start with all categories expanded
        return acc;
    }, {}));

    const handleChipCardClick = async (event, offer) => {
        if (currentUser && !checkUserExpired(currentUser)) {
            event.preventDefault();
            setShowModal(true);
            setShowModal(!showModal);
            setSelectedOffer(offer)
        } else {
            setShowLoginModal(true);
        }
    };

    const handleAppClick = (app, offerId) => {
        if(app.status === 'active'){
            history(`/deploy/${offerId}/${app.name}`);
        }else {
            history(`/profile/pricing`);
        }

    };

    const checkUserExpired =  (user) => {
        if (user) {
            // User is signed in, you can check for token expiration
            user.getIdTokenResult().then((idTokenResult) => {
                const currentTime = Date.now() / 1000; // current time in seconds
                const expirationTime = new Date(idTokenResult.expirationTime).getTime() / 1000;

                if (expirationTime < currentTime) {
                    // Token has expired, sign out user and clear local storage
                    signOut(auth).then(() => {
                        console.log("Token expired, user signed out and local storage cleared.");
                        return true;
                    }).catch((error) => {
                        console.error("Error signing out:", error);
                    });
                }
                return false;
            }).catch((error) => {
                console.error("Error checking token expiration:", error);
            });
        } else {
            // User is signed out
            console.log("No user is signed in.");
            return true;
        }
    }

    const closeModal = () => {
        setShowModal(false);
    };

    const toggleCategory = (category) => {
        setExpandedCategories(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    };

    const closeLoginModal = () => {
        setShowLoginModal(false);
    };

    const renderChipOffer = (offer, idx) => (
        <Col key={idx} xs={12} sm={6} md={4} lg={3} className="chip-offer-tile" onClick={(event) =>handleChipCardClick(event, offer)}>
            <div className="chip-header">
                <h2 className="chip-name">{offer.name}</h2>
                <span className="chip-pricing">${offer.pricing.toFixed(2)} /hr</span>
            </div>
            {/*<img variant="top" src={`/images/${offer.type}.png`} alt={offer.name} className="chip-image" />*/}
            <div className="chip-details">
                <span className="chip-cpu">{offer.cpu} CPU cores</span>
                <span className="chip-memory">{(offer.memory / 1024).toFixed(2)} GB RAM</span>
                {/*<span className="chip-disk">{(offer.disk / 1024).toFixed(2)} GB Disk</span>*/}
                {offer.spot && <span className="chip-spot">Spot Instance</span>}
            </div>
        </Col>
    );

    return (
        <Container fluid="md" className="chip-offer-list">
            {Object.keys(groupedOffers).length > 0 ? (
                Object.keys(groupedOffers).map((type, index) => (
                    <Row key={index} className={`chip-category-container ${expandedCategories[type] ? 'expanded' : 'collapsed'}`} onClick={() => toggleCategory(type)}>
                        <Row className="chip-category-header">
                            <div className="chip-header-content">
                                <h2 className="chip-category-title">
                                    {type.toUpperCase()}
                                </h2>
                            </div>
                            <button
                                className={`toggle-button ${expandedCategories[type] ? 'expanded' : ''}`}
                                aria-label={expandedCategories[type] ? "Collapse category" : "Expand category"}
                            >
                                {expandedCategories[type] ? '−' : '+'}
                            </button>
                            <div className="cheapest-label-container">
                            {type === cheapestCategory && !expandedCategories[type] && (
                                <span className="cheapest-label">Cheapest</span>
                            )}
                            </div>
                        </Row>

                        {expandedCategories[type] && (
                            <Row className="chip-category-offers">
                                {groupedOffers[type].map(renderChipOffer)}
                            </Row>
                        )}

                            <Modal show={showModal && !flagEnabled} onClose={closeModal}>
                                <div>
                                    <h2>New features are coming</h2>
                                    <p>Stay tuned for exciting updates!</p>
                                </div>
                            </Modal>
                    </Row>
                ))
            ) : (
                <p>No chip offers available.</p>
            )}

            {showModal && (
                <AppModal show={showModal} onClose={closeModal} applications={applications} handleAppClick={handleAppClick} selectedOffer={selectedOffer}/>
            )}
            <Login showLoginModal={showLoginModal} onClose={closeLoginModal} />
        </Container>
    );
};

export default ChipOfferList;
