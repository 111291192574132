import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletAmount } from '../redux/walletSlice';
import './css/Wallet.css';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import {supabase} from "../config/supabaseClient";

const Wallet = ({currentUser}) => {
    const dispatch = useDispatch();
    const amount = useSelector((state) => state.wallet.amount);
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
    const isMediumScreen = useMediaQuery({ query: '(min-width: 601px) and (max-width: 1024px)' });


    useEffect(() => {
        const fetchAmount = async () => {
            if (user) {
                const result = await dispatch(fetchWalletAmount(user.user_id));

                // Assuming result.payload contains the wallet amount.
                const walletAmount = result.payload;

                // If the wallet amount is more than 0, set an interval to fetch it every 10 minutes.
                if (walletAmount > 0) {
                    const intervalId = setInterval(() => {
                        dispatch(fetchWalletAmount(user.user_id));
                    }, 10 * 60 * 1000); // 10 minutes

                    // Clean up the interval on component unmount
                    return () => clearInterval(intervalId);
                }
            }

        }

        fetchAmount();
    }, [dispatch]);

    const handleWalletClick = () => {
        navigate("/profile/billing");
    };

    return (
        <div className={`wallet-container ${isSmallScreen ? 'small-screen' : isMediumScreen ? 'medium-screen' : 'large-screen'}`}>
            <span className="wallet-amount" onClick={handleWalletClick}>
                $ {amount}
            </span>
        </div>
    );
};

export default Wallet;
