import axios from 'axios';
import { useSelector } from 'react-redux';

const useAuthReq = () => {
    const token = useSelector((state) => state.auth.token);

    const apiRequest = async (endpoint, method, data = null) => {
        const config = {
            method,
            url: endpoint,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            data,
        };

        const response = await axios(config);
        return response;
    };

    return { apiRequest };
};

export default useAuthReq;
