import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy" style={{ paddingTop: '100px', maxWidth: '800px', textAlign: 'left' }}>
            <h1>Privacy Policy for cheapestchipforai.com</h1>

            <p>
                At <strong>cheapestchipforai.com</strong>, accessible from https://cheapestchipforai.com, one of our main priorities
                is the privacy of our visitors. This Privacy Policy document outlines the types of information that are collected
                and recorded by <strong>cheapestchipforai.com</strong> and how we use it. If you have additional questions or require
                more information about our Privacy Policy, please do not hesitate to contact us. This Privacy Policy applies only
                to our online activities and is valid for visitors to our website with regard to the information that they shared
                and/or collect in <strong>cheapestchipforai.com</strong>. This policy does not apply to any information collected
                offline or via channels other than this website.
            </p>

            <h2>Consent</h2>
            <p>
                By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            </p>

            <h2>Information We Collect</h2>
            <p>
                The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be
                made clear at the point we ask you to provide your personal information. If you contact us directly, we may receive
                additional information about you such as your name, email address, phone number, the contents of the message and/or
                attachments you may send us, and any other information you may choose to provide.
            </p>

            <h2>How We Use Your Information</h2>
            <ul>
                <li>Providing, operating, and maintaining our website</li>
                <li>Improving, personalizing, and expanding our website</li>
                <li>Understanding and analyzing how you use our website</li>
                <li>Developing new products, services, features, and functionality</li>
                <li>Communicating with you, directly or through our partners</li>
                <li>Sending you emails</li>
                <li>Finding and preventing fraud</li>
            </ul>

            <h2>Log Files</h2>
            <p>
                <strong>cheapestchipforai.com</strong> follows a standard procedure of using log files. These files log visitors when
                they visit websites. All hosting companies do this as part of hosting services' analytics. The information collected
                by log files includes IP addresses, browser type, Internet Service Provider (ISP), date and time stamps, referring/exit
                pages, and possibly the number of clicks. This information is not linked to any personally identifiable information.
            </p>

            <h2>Cookies and Web Beacons</h2>
            <p>
                Like any other website, <strong>cheapestchipforai.com</strong> uses 'cookies'. These cookies are used to store
                information, including visitors' preferences and the pages on the website that the visitor accessed or visited.
            </p>

            <h2>Advertising Partners Privacy Policies</h2>
            <p>
                You may consult this list to find the Privacy Policy for each of the advertising partners of <strong>cheapestchipforai.com</strong>.
                Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that appear on <strong>cheapestchipforai.com</strong> and are sent directly to your browser.
            </p>

            <h2>CCPA Privacy Rights</h2>
            <p>California consumers have the right to request details, deletion, or opt out of the sale of personal data.</p>

            <h2>GDPR Data Protection Rights</h2>
            <ul>
                <li>The right to access – Request copies of your personal data.</li>
                <li>The right to rectification – Correct inaccurate information.</li>
                <li>The right to erasure – Request that we erase your personal data.</li>
            </ul>

            <h2>Children's Information</h2>
            <p>
                Protecting children while they use the internet is a priority for us. We encourage parents and guardians to
                monitor their online activity. <strong>cheapestchipforai.com</strong> does not knowingly collect any Personal
                Identifiable Information from children under the age of 13.
            </p>

            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
                If you have any questions about this Privacy Policy, please <a href="mailto:contact@cheapestchipforai.com">contact us</a>.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
