import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import ChipOfferList from './ChipOfferList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import './css/LandingPage.css';
import Search from "./Search";
import LoadingSpinner from "./LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchChipOffers } from "../redux/chipOffersSlice";
import SlidingPanel from "./SlidingPanel";
import {fetchApplications} from "../redux/appsSlice";
import Footer from "./Footer";

const LandingPage = () => {
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const [sortCriteria, setSortCriteria] = useState([]);
    const dispatch = useDispatch();
    const chipOffers = useSelector((state) => state.chipOffers.offers);
    const isLoading = useSelector((state) => state.chipOffers.isLoading);
    const [filteredOffers, setFilteredOffers] = useState([]);
    const [activeSortCriteria, setActiveSortCriteria] = useState(null);


    // Media queries for responsive design
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        dispatch(fetchChipOffers());
        dispatch(fetchApplications())
    }, [dispatch]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                setShowRefreshButton(true);
                dispatch(fetchChipOffers());
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [dispatch]);

    useEffect(() => {
        setFilteredOffers(chipOffers);
    }, [chipOffers]);

    const handleFilterChange = (filters) => {
        let filtered = [...chipOffers];

        if (filters.gpuCount) {
            filtered = filtered.filter(
                (offer) => offer.gpuCount >= filters.gpuCount[0]
            );
        }
        if (filters.hourlyPrice) {
            filtered = filtered.filter(
                (offer) => offer.pricing >= filters.hourlyPrice[0]
            );
        }
        if (filters.ram) {
            filtered = filtered.filter((offer) => offer.memory / 1024 >= filters.ram);
        }
        if (filters.spot !== undefined) {
            filtered = filtered.filter((offer) => offer.spot === filters.spot);
        }
        if (filters.vcpu) {
            filtered = filtered.filter((offer) => offer.cpu >= filters.vcpu);
        }
        setFilteredOffers(filtered);
    };

    const handleSortChange = (criteria) => {
        setActiveSortCriteria(criteria);
        setSortCriteria((prevCriteria) => {
            const updatedCriteria = [...prevCriteria];
            const index = updatedCriteria.indexOf(criteria);

            if (index !== -1) {
                // Move criteria to the end to maintain the order of selection
                updatedCriteria.splice(index, 1);
                updatedCriteria.push(criteria);
            } else {
                updatedCriteria.push(criteria);
            }

            return updatedCriteria;
        });
    };

    useEffect(() => {
        if (sortCriteria.length === 0) return;

        const sortOffers = (offers) => {
            let sorted = [...offers];
            sortCriteria.forEach((criteria) => {
                switch (criteria) {
                    case 'gpuCount':
                        sorted.sort((a, b) => b.gpuCount - a.gpuCount);
                        break;
                    case 'hourlyPrice':
                        sorted.sort((a, b) => a.pricing - b.pricing);
                        break;
                    case 'ram':
                        sorted.sort((a, b) => b.memory - a.memory);
                        break;
                    case 'vcpu':
                        sorted.sort((a, b) => b.cpu - a.cpu);
                        break;
                    default:
                        break;
                }
            });
            return sorted;
        };

        setFilteredOffers((prevFilteredOffers) => sortOffers(prevFilteredOffers));
    }, [sortCriteria]);

    const resetAll = () => {
        setSortCriteria([]);
        setActiveSortCriteria(null);
        setFilteredOffers(chipOffers);
    };

    return (
        <div className={`landing-page ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'}`}>
            <SlidingPanel
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
                isMobile={isMobile}
                sortCriteria={sortCriteria}
                resetAll={resetAll}
            />
            <div className="content">
                <Search />
            </div>

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <ChipOfferList chipOffers={filteredOffers} isMobile={isMobile} flagEnabled={true}/>
            )}

            {showRefreshButton && (
                <button
                    onClick={() => dispatch(fetchChipOffers())}
                    className={`refresh-button ${isMobile ? 'mobile' : ''}`}
                >
                    <FontAwesomeIcon icon={faSyncAlt} />
                </button>
            )}
        </div>
    );
};

export default LandingPage;
