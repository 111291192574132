import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import TopBanner from './components/TopBanner';
import LandingPage from './components/LandingPage';
import SelectOptionsPage from './components/SelectOptionsPage';
import './App.css';
import './theme.css';
import Login from "./components/Login";
import Signup from "./components/Signup";
import Footer from "./components/Footer";
import {Blogs} from "./components/content/Blogs";
import Blog from "./components/content/Blog";
import Editor from "./components/content/Editor";
import Home from "./components/Home";
import AppDeploy from "./components/AppDeploy";
import NotFound from "./NotFound";
import Profile from "./components/Profile";
import AppDetails from "./components/AppDetails";
import {useDispatch, useSelector} from "react-redux";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {reloadUser} from "./redux/authSlice";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";

function App() {
    const [user] = useAuthState(auth);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reloadUser());
    }, [dispatch]);
    const PrivateRoute = ({ children }) => {
        return user ? children : <Navigate to="/login" />
    }
    const toggleLoginModal = () => {
        setShowLoginModal(!showLoginModal);
    };
    return (
        <Router>
            <div className="App">
                <TopBanner />
                <Routes>
                    <Route path="/explore" element={<LandingPage />} />
                    <Route path="/select-options/:id" element={<SelectOptionsPage />} /> {/* Define route for SelectOptionsPage */}
                    <Route path="/login" element={!user ? <Login showLoginModal={true} onClose={toggleLoginModal}/> : <Navigate to="/" />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/edit/:blogId" element={<Editor/>} />
                    <Route path="/edit/new" element={<Editor/>} />
                    <Route path="/blog/:blogId" element={<Blog/>} />
                    <Route path="/blogs" element={<Blogs/>} />
                    <Route path="/profile/:page" element={<Profile/>} />
                    <Route path="/" element={<Home/>} />
                    <Route path="/deploy/:offerId/:app" element={<PrivateRoute><AppDeploy/></PrivateRoute>} />
                    <Route path="/deploy/apps/:uuid" element={<PrivateRoute><AppDetails/></PrivateRoute>} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
