import React from 'react';
import { Container, Grid, Typography, Link, Box } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import DiscordIcon from '@mui/icons-material/SportsEsports'; // Replace with appropriate icon
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
    return (
        <Box
            sx={{
                width: '100%',
                background: 'linear-gradient(90deg, #000000, #434343)', // Customize your gradient here
                color: '#fff',
                py: 2,
                zIndex: 7000,
                marginTop: '50px'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={3} spacing={2}>
                        <Typography variant="h6" gutterBottom>
                            Products
                        </Typography>
                        <Box mb={2}>
                            <Link href="#" color="inherit" underline="none">
                                Managed AI Deployer
                            </Link><br />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <Typography variant="h6" gutterBottom>
                            Resources
                        </Typography>
                        <Box mb={2}>
                            <Link href="#" color="inherit" underline="none">
                                Docs
                            </Link>
                        </Box>
                        <Box mb={2}>
                            <Link href="#" color="inherit" underline="none">
                                FAQ
                            </Link>
                        </Box>
                        <Box mb={2}>
                            <Link href="blogs" color="inherit" underline="none">
                                Blog
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom>
                            Company
                        </Typography>
                        <Box mb={2}>
                            <Link href="#" color="inherit" underline="none">
                                About
                            </Link><br />
                        </Box>
                        <Box mb={2}>
                            <Link href="privacy" color="inherit" underline="none">
                                Privacy Policy
                            </Link><br />
                        </Box>
                        <Box mb={2}>
                            <Link href="terms" color="inherit" underline="none">
                                Terms of Service
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom>
                            Contact
                        </Typography>
                        <Box mb={2}>
                            <Link href="mailto:support@cheapestchipforai.com" color="inherit" underline="none">
                                support@cheapestchipforai.com
                            </Link><br />
                        </Box>
                        <Box mb={2}>
                            <Link href="mailto:enquiry@cheapestchipforai.com" color="inherit" underline="none">
                                enquiry@cheapestchipforai.com
                            </Link><br />
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={4} display="flex" justifyContent="center">
                    <Link href="https://github.com/chipwallet" color="inherit" sx={{ mx: 1 }}>
                        <GitHubIcon />
                    </Link>
                    <Link href="#" color="inherit" sx={{ mx: 1 }}>
                        <DiscordIcon />
                    </Link>
                    <Link href="https://x.com/CheapChipForAI" color="inherit" sx={{ mx: 1 }}>
                        <TwitterIcon />
                    </Link>

                </Box>
                <Box mt={4} textAlign="center">
                    <img
                        src="/blog_logo.png"
                        alt="CheapestChipForAI"
                        style={{ width: '50px', marginBottom: '10px' }}
                    />
                    <Typography variant="body2">
                        © 2024 CheapestChipForAI. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
    