import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './css/Home.css';
import {Button} from "@measured/puck";
import {Link, useNavigate} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import Enquiry from "./Enquiry";
import Login from "./Login";
import Modal from "./Modal";
import {useSelector} from "react-redux";
import {EmojiEvents} from "@mui/icons-material";
import Pricing from "./Pricing";
import Footer from "./Footer";

ChartJS.register(ArcElement, Tooltip, Legend);

export const HomePage = () => {
    const navigate = useNavigate();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const user = useSelector((state) => state.auth.user);


    const handleExploreClick = () => {
        navigate('/explore');
    }

    const toggleLoginModal = () => {
        setShowLoginModal(!showLoginModal);
    };

    const handleFreeSubscriptionClick = () => {
        if(user) {
            handleExploreClick()
        } else{
            toggleLoginModal();
        }
    };
    const handlePROSubscriptionClick = () => {
        if(user) {
            window.location.href = "https://cheapestchipforai.lemonsqueezy.com/buy/1e22aec6-f596-4aec-a692-a419d4b8a932?logo=0&desc=0";
        } else{
            toggleLoginModal();
        }
    };

    const renderModalContent = () => {

        return (<Pricing/>)

    };


    return (
        <div className="home-page">
            <div className="big-card"> {/* Big card container */}
                <div className="matrix-falling">
                    {/* Add falling effect divs */}
                    {[...Array(100)].map((_, i) => (
                        <div key={i}></div>
                    ))}
                </div>
                <Matrix />
                <div className="card-container">
                    <GradientCard title="All Cloud Chips" content="" />
                    <GradientCard title="Auto workload swapping" content="" />
                    <GradientCard title="Best Price alerts" content="" />
                </div>

            </div>
            <div className="big-card">
                {renderModalContent()}
            </div>
            <Login showLoginModal={showLoginModal} onClose={toggleLoginModal} />
            <Footer />

        </div>
    );
}

export default HomePage;

const GradientCard = ({ title, content }) => {
    return (
        <div className="gradient-card">
            <h3>{title}</h3>
            <p>{content}</p>
        </div>
    );
};


const Matrix = () => {
    return (
        <div className="matrix">
            <div className="matrix-text">Find your cheapest AI deal instantly</div>
        </div>
    );
};


