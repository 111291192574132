import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, googleProvider, appleProvider } from '../firebase';
import {onAuthStateChanged, signInWithPopup, signOut} from 'firebase/auth';
import { supabase } from "../config/supabaseClient";
import { jwtDecode } from 'jwt-decode';

const fetchOrCreateUser = async (user) => {
    // Check if the user exists in the database
    let { data: existingUser, error } = await supabase
        .from('users')
        .select('*')
        .eq('email', user.email)
        .single();

    if (error && error.code !== 'PGRST116') {
        throw error;
    }

    // If the user does not exist, insert the user details
    if (!existingUser) {
        const { data, error: insertError } = await supabase
            .from('users')
            .insert([
                {
                    email: user.email,
                    full_name: user.displayName,
                    sso_provider: user.providerData[0].providerId,
                    sso_id: user.uid,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                },
            ])
            .select()
            .single();

        if (insertError) {
            throw insertError;
        }

        existingUser = data;
    }

    return existingUser;
};

const fetchUserSubscription = async (userId) => {
    const { data, error } = await supabase
        .from('user_subscriptions')
        .select(`
            subscription_id,
            user_id,
            plan_id,
            start_date,
            end_date,
            status,
            created_at,
            updated_at,
            subscription_plans (
                plan_name,
                description,
                price
            )
        `)
        .eq('user_id', userId);

    if (error) throw error;

    return data[0];
};

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async () => {
    const result = await signInWithPopup(auth, googleProvider);
    const user_temp = result.user;
    const token = user_temp.accessToken;

    // Fetch or create the user in the database
    const user = await fetchOrCreateUser(user_temp);

    // Fetch the user's subscription details
    const subscription = await fetchUserSubscription(user.user_id);

    return { user, subscription, token };
});

export const signInWithApple = createAsyncThunk('auth/signInWithApple', async () => {
    const result = await signInWithPopup(auth, appleProvider);
    const user_temp = result.user;

    // Fetch or create the user in the database
    const user = await fetchOrCreateUser(user_temp);

    // Fetch the user's subscription details
    const subscription = await fetchUserSubscription(user.user_id);

    return { user, subscription };
});

export const signOutUser = createAsyncThunk('auth/signOutUser', async () => {
    await signOut(auth);
    return null;
});

export const reloadUser = createAsyncThunk('auth/reloadUser', async () => {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const idTokenResult = await user.getIdTokenResult();
                    const currentTime = Date.now() / 1000;
                    const expirationTime = idTokenResult.expirationTime
                        ? new Date(idTokenResult.expirationTime).getTime() / 1000
                        : 0;

                    if (expirationTime < currentTime) {
                        await signOut(auth);
                        console.log("Token expired, user signed out.");
                        resolve(null); // Resolve with null if token is expired
                    } else {
                        const user_new = await fetchOrCreateUser(user);
                        const subscription = await fetchUserSubscription(user_new.user_id);
                        resolve({ user: user_new, subscription, token: idTokenResult.token });
                    }
                } catch (error) {
                    console.error("Error checking token expiration:", error);
                    reject(error);
                }
            } else {
                console.log("No user is signed in.");
                resolve({}); // Resolve with null if no user is signed in
            }
        });
    });
});

const isTokenExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.exp < Date.now() / 1000; // Current time in seconds
    } catch (error) {
        return true; // If token is invalid, consider it expired
    }
};

const initialState = {
    user: null,
    token: null,
    subscription: null,
    status: 'idle',
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            const { user, token, subscription } = action.payload;
            if (token && !isTokenExpired(token)) {
                state.user = user;
                state.token = token;
                state.subscription = subscription;
            } else {
                state.user = null;
                state.token = null;
                state.subscription = null;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signInWithGoogle.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(signInWithGoogle.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { user, subscription, token } = action.payload;
                state.user = user;
                state.token = token;
                state.subscription = subscription;
            })
            .addCase(signInWithGoogle.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(signInWithApple.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(signInWithApple.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { user, subscription, token } = action.payload;
                state.user = user;
                state.token = token;
                state.subscription = subscription;
            })
            .addCase(signInWithApple.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(reloadUser.fulfilled, (state, action) => {
                console.log("reloaded")
                state.status = 'succeeded';
                const { user, subscription, token } = action.payload;
                state.user = user;
                state.token = token;
                state.subscription = subscription;
            })
            .addCase(signOutUser.fulfilled, (state) => {
                state.user = null;
                state.token = null;
                state.subscription = null;
            });
    },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;
