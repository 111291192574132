import React from 'react';

const TermsOfService = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Terms of Service</h1>

            <section>
                <h2 style={styles.subHeader}>Agreement to Terms</h2>
                <p style={styles.paragraph}>
                    We are Cheapest Chip for AI ("Company," "we," "us," or "our"), a company registered in [insert company registration details and address].
                </p>
                <p style={styles.paragraph}>
                    These Terms of Service constitute a legally binding agreement between you, whether personally or on behalf of an entity ("you"), and Cheapest Chip for AI, concerning your access to and use of our services and website. By accessing or using our services, you confirm that you have read, understood, and agreed to these Terms. IF YOU DO NOT AGREE TO THESE TERMS, YOU MUST NOT USE OUR SERVICES.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>1. Our Services</h2>
                <p style={styles.paragraph}>
                    Our Services are not intended for distribution or use in any jurisdiction where such use would violate law or regulation or would subject us to registration requirements within such jurisdiction. If you access the Services from other locations, you do so at your own risk and are responsible for compliance with local laws.
                </p>
                <p style={styles.paragraph}>
                    Our Services are not designed to comply with industry-specific regulations (e.g., HIPAA, FISMA), so if you are subject to such laws, please refrain from using the Services. Additionally, you agree not to use the Services in any manner that would violate the Gramm-Leach-Bliley Act (GLBA).
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>2. Intellectual Property Rights</h2>
                <h3 style={styles.subSectionHeader}>Our Intellectual Property</h3>
                <p style={styles.paragraph}>
                    We own or hold licenses to all intellectual property rights in our Services, including the content, code, software, website design, text, photos, graphics, and other materials ("Content"), as well as trademarks and logos ("Marks").
                </p>
                <p style={styles.paragraph}>
                    The Content and Marks are protected by copyright, trademark laws, and various international laws. All Content and Marks are provided "AS IS" for your personal, non-commercial use or for internal business purposes only.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>3. User Representations</h2>
                <p style={styles.paragraph}>
                    By using our Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal Terms; ( 4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise; (6) you will not use the Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate any applicable law or regulation.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>4. User Registration</h2>
                <p style={styles.paragraph}>
                    You may be required to register an account to use the Services. You agree to keep your password confidential and are responsible for any activity on your account. We reserve the right to remove or change a username if we determine, in our sole discretion, that it is inappropriate.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>5. Purchases and Payment</h2>
                <p style={styles.paragraph}>
                    We accept payments via: Visa, Mastercard. You agree to provide accurate account and payment information for purchases. You authorize us to charge your chosen payment provider for amounts due upon placing an order. We may, at our discretion, limit or cancel orders, especially those that appear to be placed by resellers or distributors.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>6. Subscriptions</h2>
                <h3 style={styles.subSectionHeader}>Billing and Renewal</h3>
                <p style={styles.paragraph}>
                    e.g., by topping up their balance manually using their credit card.
                </p>
                <h3 style={styles.subSectionHeader}>Cancellation</h3>
                <p style={styles.paragraph}>
                    You can cancel your subscription at any time by contacting us using the contact information provided below. Your cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied with our Services, please email us at support@cheapestchipforai.com.
                </p>
                <h3 style={styles.subSectionHeader}>Fee Changes</h3>
                <p style={styles.paragraph}>
                    We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with applicable law.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>7. Software</h2>
                <p style={styles.paragraph}>
                    We may include software for use in connection with our Services. If such software is accompanied by an end user license agreement ("EULA"), the terms of the EULA will govern your use of the software. If such software is not accompanied by a EULA, then we grant you a non-exclusive, revocable, personal, and non-transferable license to use such software solely in connection with our Services and in accordance with these Legal Terms. Any software and any related documentation is provided "AS IS" without warranty of any kind, either express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. You accept any and all risk arising out of use or performance of any software. You may not reproduce or redistribute any software except in accordance with the EULA or these Legal Terms.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>8. Prohibited Activities</h2>
                <p style={styles.paragraph}>
                    You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
                </p>
                <p style={styles.paragraph}>
                    As a user of the Services, you agree not to:
                </p>
                <ul style={styles.list}>
                    <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                    <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
                    <li>Circumvent, disable, or otherwise interfere with security-related features of the Services.</li>
                    <li>Disparage, tarnish, or otherwise harm us and/or the Services in our opinion.</li>
                    <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
                    <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                    <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
                    <li>Engage in unauthorized framing of or linking to the Services.</li>
                    <li>Upload or transmit viruses, Trojan horses, or other material that interferes with the Services.</li>
                    <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using data mining, robots, or similar data gathering and extraction tools.</li>
                    <li>Attempt to impersonate another user or person or use the username of another user.</li>
                    <li>Upload or transmit any material that acts as a passive or active information collection or transmission mechanism.</li>
                    <li>Interfere with, disrupt, or create an undue burden on the Services or the networks connected to the Services.</li>
                    <li>Harass, annoy, intimidate, or threaten any of our employees or agents.</li>
                    <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services.</li>
                    <li>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                    <li>Use or launch any unauthorized script or software, or use a buying agent or purchasing agent to make purchases on the Services.</li>
                    <li>Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for sending unsolicited email.</li>
                    <li>Use the Services as part of any effort to compete with us.</li>
                </ul>
            </section>

            <section>
                <h2 style={styles.subHeader}>9. User Generated Contributions</h2>
                <p style={styles.paragraph}>
                    The Services does not offer users the ability to submit or post content.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>10. Contribution License</h2>
                <p style={styles.paragraph}>
                    You and Services agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings).
                </p>
                <p style={styles.paragraph}>
                    By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>11. Social Media</h2>
                <p style={styles.paragraph}>
                    As part of the functionality of the Services, you may link your account with online accounts you have with third-party service providers ("Third-Party Accounts"). By granting us access to any Third-Party Accounts, you understand that we may access, make available, and store any content that you have provided to and stored in your Third-Party Account.
                </p>
                <p style={styles.paragraph}>
                    PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>12. Third-Party Websites and Content</h2>
                <p style={styles.paragraph}>
                    The Services may contain links to other websites ("Third-Party Websites") as well as content from third parties ("Third-Party Content"). We are not responsible for Third-Party Websites or Third-Party Content posted on or available through the Services.
                </p>
                <p style={styles.paragraph}>
                    Inclusion of, linking to, or permitting the use of any Third-Party Websites or Content does not imply approval or endorsement by us. Any purchases you make through Third-Party Websites will be exclusively between you and the applicable third party.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>13. Services Management</h2>
                <p style={styles.paragraph}>
                    We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
                </p>

                <h2 style={styles.subHeader}>14. Privacy Policy</h2>
                <p style={styles.paragraph}>
                    We care about data privacy and security. Please review our Privacy Policy. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in Singapore and the United States. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Singapore and the United States, then through your continued use of the Services, you are transferring your data to Singapore and the United States, and you expressly consent to have your data transferred to and processed in Singapore and the United States.
                </p>

                <h2 style={styles.subHeader}>15. Term and Termination</h2>
                <p style={styles.paragraph}>
                    These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                </p>

                <h2 style={styles.subHeader}>16. Modifications and Interruptions</h2>
                <p style={styles.paragraph}>
                    We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
                </p>

                <h2 style={styles.subHeader}>17. Governing Law</h2>
                <p style={styles.paragraph}>
                    These Legal Terms are governed by and interpreted following the laws of Singapore, and the use of the United Nations Convention of Contracts for the International Sales of Goods is expressly excluded. If your habitual residence is in the EU, and you are a consumer, you additionally possess the protection provided to you by obligatory provisions of the law in your country to residence. cheapestchipforai and yourself both agree to submit to the non-exclusive jurisdiction of the courts of Singapore, which means that you may make a claim to defend your consumer protection rights in regards to these Legal Terms in Singapore, or in the EU country in which you reside.
                </p>

                <h2 style={styles.subHeader}>18. Dispute Resolution</h2>
                <p style={styles.paragraph}>
                    To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a "Dispute" and collectively, the "Disputes") brought by either you or us (individually, a "Party" and collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
                </p>
            </section>

            <section>
                <h2 style={styles.subHeader}>19. Corrections</h2>
                <p style={styles.paragraph}>
                    There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
                </p>

                <h2 style={styles.subHeader}>20. Disclaimer</h2>
                <p style={styles.paragraph}>
                    THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                </p>

                <h2 style={styles.subHeader}>21. Limitations of Liability</h2>
                <p style={styles.paragraph}>
                    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE ZERO (0) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $0.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                </p>

                <h2 style={styles.subHeader}>22. Indemnification</h2>
                <p style={styles.paragraph}>
                    You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3) any breach of your representations and warranties set forth in these Legal Terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                </p>

                <h2 style={styles.subHeader}>23. User Data</h2>
                <p style={styles.paragraph}>
                    We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
                </p>

                <h2 style={styles.subHeader}>24. Electronic Communications, Transactions, and Signatures</h2>
                <p style={styles.paragraph}>
                    Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                </p>

                <h2 style={styles.subHeader}>25. California Users and Residents</h2>
                <p style={styles.paragraph}>
                    If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
                </p>

                <h2 style={styles.subHeader}>26. Miscellaneous</h2>
                <p style={styles.paragraph}>
                    These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
                </p>
            </section>
            <section id="contact-us">
                <h2>Contact Us</h2>
                <p>
                    In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us
                    at support@cheapestchipforai.com.
                </p>
            </section>

        </div>
    );
};

const styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        paddingTop: '100px',
        textAlign: 'left', // Left-aligns the text
    },
    header: {
        fontSize: '32px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    subHeader: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px',
    },
    subSectionHeader: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginTop: '10px',
    },
    paragraph: {
        fontSize: '16px',
        lineHeight: '1.6',
        marginBottom: '10px',
    },
};

export default TermsOfService;
