import React, {useEffect, useState} from "react";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {supabase} from "../config/supabaseClient";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router-dom";


const DeployedInstances = ( { selected }) => {

    const [instances, setInstances] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const history = useNavigate();


    useEffect(() => {
        const getApps = async () => {
            if (selected === 'instances') {
                const deployedInstances = await fetchDeployedInstancesByUser();
                setInstances(deployedInstances);
            }
        };
        getApps().then(r => {});
    }, [selected]);

    const fetchDeployedInstancesByUser = async () => {
        if(user) {
            const {data, error} = await supabase
                .from('users')
                .select(`
            user_id,
            email,
            deployed_apps:deployed_apps (
                deployed_app_id,
                deployed_app_name,
                status,
                instances:instances (
                    instance_id,
                    instance_name,
                    status
                )
            )
        `)
                .eq('user_id', user.user_id);


            if (error) {
                console.error('Error fetching deployed instances:', error);
                return [];
            }
            console.log(data)

            return data.flatMap(user =>
                user.deployed_apps.flatMap(app => app.instances)
            );

        } else {
            history(`/explore`);
            return [];
        }

    }

        return (
        <Box sx={{
            marginBottom: '40px',
            justifyContent: isMobile ? 'center' : 'flex-start',
        }}>
            <Typography variant="h4">Instances</Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2 , background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(100, 100, 100, 0.7))'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {instances.length > 0 ? (
                            instances.map(app => (
                                <TableRow
                                    key={app.deployed_app_id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{app.instance_name}</TableCell>
                                    <TableCell>{app.status}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={2}>No instances are deployed yet.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default DeployedInstances;

